// Color Variables

:root {
    --white: #ffffff;
    --cyan1: #b5e5fd;
    --gray0: #f2f5f7;
    --gray1: #ebeff2;
    --gray2: #e2e8ed;
    --gray3: #d8e0e5;
    --gray4: #c5d1d8;
    --gray5: #b6c3cc;
    --gray6: #a3b5bf;
    --gray7: #96a8b2;
    --gray8: #889aa5;
    --gray9: #748b99;
    --gray10: #677e8c;
    --gray11: #59717f;
    --gray12: #4c6472;
    --gray13: #425866;
    --gray14: #364c59;
    --gray15: #2c404c;
    --gray16: #23343f;
    --gray18: #121f26;
    --gray20: #05090c;
    --black: #000000;
    --aqua1: #b6cedd;
    --aqua2: #a8c4d6;
    --aqua3: #99bad0;
    --orange4: #ecaf68;
    --neutral-gray1: #e0e0e0;
    --neutral-gray2: #ccc;
    --neutral-gray3: #b8b8b8;
    --neutral-gray4: #a3a3a3;
    --yellow-green6: #7bbc00;
    --yellow-green7: #679f00;
    --yellow-green8: #4f7b00;
    --status-yellow3: #fdb400;
    --status-blue1: #e6f5ff;
    --status-blue3: #007acc;
    --select-default: #09819c;
    --primary-default: #0063a6;
    --status-red1: #ffecec;
    --status-red2: #f9a59f;
    --status-red4: #b40000;
    --status-orange4: #cc5500;
    --status-yellow1: #fff6d9;
    --primary: var(--status-blue3);
}

// Color Classes

.color-inherit {
    color: inherit;
}

.color-white {
    color: var(--white);
}

.color-gray4 {
    color: var(--gray4);
}

.color-gray9 {
    color: var(--gray9);
}

.color-gray10 {
    color: var(--gray10);
}

.color-gray12 {
    color: var(--gray12);
}

.color-gray13 {
    color: var(--gray13);
}

.color-gray14 {
    color: var(--gray14);
}

.color-gray15 {
    color: var(--gray15);
}

.color-gray16 {
    color: var(--gray16);
}

.color-gray18 {
    color: var(--gray18);
}

.color-black {
    color: black;
}

.color-yellow-green6 {
    color: var(--yellow-green6);
}

.color-yellow-green7 {
    color: var(--yellow-green7);
}

.color-yellow-green8 {
    color: var(--yellow-green8);
}

.color-status-red2 {
    color: var(--status-red2);
}

.color-status-red4 {
    color: var(--status-red4);
}

.color-primary {
    color: var(--primary);
}

.color-primary-default {
    color: var(--primary)-default;
}

// Background

.bg-white {
    background-color: var(--white);
}

.bg-gray0 {
    background-color: var(--gray0);
}

.bg-gray1 {
    background-color: var(--gray1);
}

.bg-gray18 {
    background-color: var(--gray18);
}

.bg-yellow-green6 {
    background-color: var(--yellow-green6);
}

.bg-primary {
    background-color: var(--primary);
}

.bg-status-yellow3 {
    background-color: var(--status-yellow3);
}

.bg-status-red1 {
    background-color: var(--status-red1);
}

.bg-status-red4 {
    background-color: var(--status-red4);
}

.bg-status-yellow1 {
    background-color: var(--status-yellow1);
}
