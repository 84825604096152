.justify-self-start {
    justify-self: start;
}

.justify-self-end {
    justify-self: end;
}

.justify-self-center {
    justify-self: center;
}
