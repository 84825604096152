$max: 3;
$offset: 0.25;
$unit: 'rem';

@mixin spacing($className, $styleName) {
    $i: 0.25;
    $j: 1;

    @while $i <= $max {
        #{$className + $j} {
            #{$styleName}: #{$i + $unit};
        }

        #{$className}n#{$j} {
            #{$styleName}: -#{$i + $unit};
        }

        $i: $i + $offset;
        $j: $j + 1;
    }
}

// Margin

@include spacing('.m-', 'margin');
@include spacing('.ml-', 'margin-left');
@include spacing('.mr-', 'margin-right');
@include spacing('.mt-', 'margin-top');
@include spacing('.mb-', 'margin-bottom');

.ml-auto {
    margin-left: auto;
}

// Padding

@include spacing('.p-', 'padding');
@include spacing('.pl-', 'padding-left');
@include spacing('.pr-', 'padding-right');
@include spacing('.pt-', 'padding-top');
@include spacing('.pb-', 'padding-bottom');
