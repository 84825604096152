.block {
    display: block;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

.flex {
    display: flex;
}

.inline-flex {
    display: inline-flex;
}

.table {
    display: table;
}

.grid {
    display: grid;
}

.inline-grid {
    display: inline-grid;
}

.contents {
    display: contents;
}

.list-item {
    display: list-item;
}

.hidden {
    display: none;
}
