/* You can add global styles to this file, and also import other style files */

@import 'styles/align-items';
@import 'styles/align-self';
@import 'styles/breakpoints';
@import 'styles/colors';
@import 'styles/common';
@import 'styles/cursors';
@import 'styles/display';
@import 'styles/flex';
@import 'styles/fonts';
@import 'styles/gaps';
@import 'styles/grid';
@import 'styles/justify-self';
@import 'styles/overflow';
@import 'styles/override';
@import 'styles/positions';
@import 'styles/sizing';
@import 'styles/spacing';
@import 'styles/text-align';
@import 'styles/text-transform';
@import 'styles/text-wrap';
@import 'styles/theme';
@import 'styles/white.space';

html {
    --wfe-branding-bar-height: 45px;
    --wfe-navbar-height: 60px;
    --mat-menu-container-shape: 2px;
    --mat-standard-button-toggle-divider-color: var(--gray5);
    --mat-standard-button-toggle-height: 33px;
    --mat-standard-button-toggle-selected-state-text-color: var(--primary);
    --mat-standard-button-toggle-selected-state-background-color: var(--white);
    --mat-full-pseudo-checkbox-unselected-icon-color: var(--neutral-gray3);
    --mat-option-label-text-size: 13px;
    --mat-select-trigger-text-size: 13px;
    --mdc-checkbox-state-layer-size: 29px;
    --mdc-checkbox-selected-hover-state-layer-color: transparent;
    --mdc-checkbox-selected-pressed-state-layer-color: transparent;
    --mdc-checkbox-unselected-pressed-state-layer-color: transparent;
    --mat-checkbox-label-text-color: #0c1419;
    --mat-checkbox-disabled-label-color: #4f4c4c;
    --mdc-filled-button-container-height: 30px;
    --mdc-text-button-container-height: 30px;
    --mat-select-placeholder-text-color: var(--gray12);
    --mdc-snackbar-container-color: var(--gray1);
    --mdc-snackbar-container-shape: 0;
    --mdc-snackbar-supporting-text-color: var(--gray20);
    --mat-divider-color: #d9d9d9;
    --mat-datepicker-calendar-text-font: 'Inter';
    --mat-datepicker-calendar-text-size: 13px;
    --mdc-checkbox-unselected-focus-state-layer-color: unset;
    --mat-datepicker-calendar-date-hover-state-background-color: var(--gray3);
    --mat-datepicker-calendar-date-today-selected-state-outline-color: unset;
    --mat-select-trigger-text-weight: 400;
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 122, 204, 0.5);
    --mdc-checkbox-unselected-icon-color: #869da9;
    --mdc-checkbox-unselected-focus-icon-color: #869da9;
    --mdc-linear-progress-active-indicator-height: 100%;
    --mdc-linear-progress-track-height: 100%;
    --mat-filled-button-horizontal-padding: 10px;
}

html,
body {
    height: 100%;
    scrollbar-width: thin;
    overflow-y: hidden;

    * {
        scrollbar-width: thin;
        box-sizing: border-box;
    }
}

body {
    margin: 0;
    font-family: 'Inter', Verdana, Geneva, Tahoma, sans-serif;
    color: var(--gray20);

    @extend .text-sm;
}
