.info-section {
    display: grid;
    gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    padding: 10px 0;
}

.bordered {
    & > * {
        border-right: 1px solid var(--gray3);
        padding-right: 10px;

        &:last-child {
            border-right: none;
            padding-right: 0;
        }
    }
}

.summary-box {
    border: 1px solid var(--gray4);
    background-color: var(--gray0);
    padding: 12px;
}

.disabled {
    pointer-events: none;
}

.center-before {
    transform: translateX(50%);
}

.center-after {
    transform: translateX(-40%);
}
