// Flex Wrap

.flex-wrap {
    flex-wrap: wrap;
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse;
}

.flex-nowrap {
    flex-wrap: nowrap;
}

// Flex Direction

.flex-column {
    flex-direction: column;
}

// Justify Content

.justify-start {
    justify-content: flex-start;
}

.justify-end {
    justify-content: flex-end;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-evenly {
    justify-content: space-evenly;
}

.flex-1 {
    flex: 1;
}
