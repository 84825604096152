// Width

.w-full {
    width: 100%;
}

.w-screen {
    width: 100vw;
}

.w-min {
    width: min-content;
}

.w-max {
    width: max-content;
}

.w-fit {
    width: fit-content;
}

.w-auto {
    width: auto;
}

// Height

.h-full {
    height: 100%;
}

.h-screen {
    height: 100vh;
}

.h-min {
    height: min-content;
}

.h-max {
    height: max-content;
}

.h-fit {
    height: fit-content;
}

.w-auto {
    width: auto;
}

.h-max-screen {
    max-height: 100vh;
}
