// Material Button Override

.mdc-button__label {
    display: flex;
    align-items: center;
}

// Material Chip Override

.mat-mdc-chip.mat-mdc-standard-chip {
    font-size: 12px;
    --mdc-chip-container-height: 30px !important
;
}

input.mat-mdc-chip-input {
    margin-left: 0 !important;
}

// Material Menu Override

.mat-mdc-menu-panel {
    min-width: 30px !important;
    max-width: unset !important;
    border-radius: 2px;
    box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.12);

    .mat-mdc-menu-content {
        padding: 0px !important;
    }

    .mat-button-toggle-checked {
        border: none;

        .mat-button-toggle-label-content {
            border-radius: 3px;
            border: 1px solid;
        }
    }
    .mat-button-toggle-group-appearance-standard
        .mat-button-toggle-appearance-standard
        + .mat-button-toggle-appearance-standard {
        border: none;
    }

    .mat-pseudo-checkbox {
        display: none;
    }

    .mat-mdc-menu-item {
        min-height: 36px;
    }
}

.mat-mdc-menu-trigger {
    display: flex;
}

// Material Checkbox Override

.mat-pseudo-checkbox,
.mdc-checkbox__background {
    width: 14px !important;
    height: 14px !important;
}

.mat-pseudo-checkbox-full {
    margin-right: 10px !important;
    &.mat-pseudo-checkbox-checked::after {
        width: 8px !important;
        height: 3px !important;
        border-width: 2px;
    }
}

.mdc-checkbox {
    padding: 4px !important;
}

// Material Select Override

.mat-mdc-select {
    &[aria-expanded='true'] {
        .mat-mdc-select-arrow-wrapper {
            transform: rotate(180deg);
        }
    }

    &-trigger {
        height: inherit;
        padding: 3px 10px;
    }

    &-arrow {
        opacity: 0;

        &-wrapper {
            width: 24px;
            background-image: url('/assets/icons/chevron-down.svg') !important;
            background-repeat: no-repeat;
            background-position: center;
        }
    }
}

.mat-mdc-select-panel,
.mat-mdc-autocomplete-panel {
    min-width: min-content;
}

.mdc-list-item__primary-text {
    white-space: nowrap !important;
}

.mat-mdc-optgroup-label {
    color: var(--gray12);
    font-size: 12px;
    font-weight: 600;
    min-height: unset !important;
}

// Material Option Override

.mat-mdc-option {
    min-height: 30px !important;
}

// Material Snackbar

.mat-mdc-snack-bar-container .mdc-snackbar__surface {
    max-width: 418px !important;
}

.mdc-snackbar__label {
    padding: 0px !important;
}

// Material Datepicker

.mat-calendar-body-cell-content {
    border-radius: 0 !important;
}

// Marerial Radio

.mat-mdc-radio-button.mat-accent {
    --mdc-radio-selected-icon-color: #{var(--select-default)} !important;
}

// HTML Tags

p {
    margin: 0;
    padding: 0;
}

input,
select,
textarea,
button {
    font-family: inherit;
}

input {
    border: 0;
    outline: 0;
    padding: 0;
    margin: 0;
    padding-left: 5px;

    @extend .text-s;

    &:focus {
        outline: none !important;
    }

    &[type='checkbox'] {
        accent-color: var(--primary);
    }
}

button {
    border-radius: 0 !important;
}

ul,
li {
    margin: 0;
}

a {
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
}

a:link,
a:visited,
a:active,
a:hover {
    color: var(--primary)-default;
}
