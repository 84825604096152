.grid-flow-row {
    grid-auto-flow: row;
}

.grid-flow-col {
    grid-auto-flow: column;
}

.grid-flow-dense {
    grid-auto-flow: dense;
}
